<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <LoaderBig v-if="currentProjects === null" />
    <TagsList />

  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import LoaderBig from "@/components/LoaderBig"
import TagsList from "./_tagsList"
import { tagsService } from "@/helpers/fakebackend/tags.service";

export default {
  name: "Widgets",
  components: { Layout, PageHeader, LoaderBig, TagsList },
  page: {
    title: "User Data",
    meta: [{ name: "User Data" }],
  },
  data: function () {
    return {
      title: this.$t('UserData.Title'),
      items: [
        {
          text: this.$t('General.Breadcrumbs.Main'),
          href: "/",
        },
        {
          text: this.$t('General.Breadcrumbs.UserData'),
          active: true,
        },
      ],
      project: null,
      tags:[]
    }
  },
  computed: {
    currentProjects: function() {
      return this.$store.getters['projects/getCurrentProjects']
    }
  },
  methods: {
    ...tagsService
  },
  mounted() {
    if (this.currentProjects) {
      this.getTags().then(result => {
        this.tags = result
      })
    }


    // this.$store.dispatch('projects/getCurrentProjects').then((project_id) => {
    //   if (!project_id) {
    //     this.project = false
    //     return
    //   }
    //
    //   this.project = this.$store.getters['projects/getCurrentProjects'].id
    //
    //
    //   // this.getWidgets(this.currentProjects.id).then(result => {
    //   //   this.widgets = result
    //   //   this.project = true
    //   // })
    // })


  }
}
</script>

<style scoped>

</style>
