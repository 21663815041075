<template>
  <div>
    <LoaderBig v-if="bigLoader" />

    <b-row v-if="!bigLoader">
      <b-col
          v-for="(item, i) in tags"
          :key="i"
          cols="4"
      >
        <b-card>
          <b-row align-h="between">
            <b-col cols="auto">
              <h5>{{getTagName(item)}}</h5>
            </b-col>
            <b-col cols="auto">
              <b-badge v-if="item.status" variant="success">{{$t('Tags.Status.1')}}</b-badge>
              <b-badge v-if="!item.status" variant="warning">{{$t('Tags.Status.0')}}</b-badge>
            </b-col>
          </b-row>

<!--          <b-row align-h="between" class="mt-2 ">-->
<!--            <b-col cols="auto">-->
<!--              <span>Поля:</span>-->
<!--            </b-col>-->
<!--            <b-col cols="auto">-->
<!--              Данных: 0-->
<!--            </b-col>-->
<!--          </b-row>-->

<!--          <ul class="list-unstyled mt-1 font-size-12">-->
<!--            <li>Полу 1 <small class="text-muted">- text</small></li>-->
<!--            <li>Поле 2 <small class="text-muted">- email</small></li>-->
<!--          </ul>-->

          <b-row align-h="end" class="mt-2 ">
            <b-col cols="auto">
              <b-button @click="goToDataTag(item)" variant="primary">{{$t('General.Base.Data')}}</b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {tagsService} from "@/helpers/fakebackend/tags.service";
import LoaderBig from "@/components/LoaderBig"

export default {
  name: "tagsList",
  components: { LoaderBig },
  data: function () {
    return {
      tags:[],
      bigLoader: true
    }
  },
  computed: {
    currentProject: function() {
      return this.$store.getters['projects/getCurrentProjects']
    }
  },
  watch: {
    currentProject: function () {
      this.getTagsFromServer()
    }
  },
  methods: {
    ...tagsService,
    getTagName(tag) {
      if (tag.name !== '') {
        return '№' + tag.id + ' ' +tag.name
      }
      return 'Tag:' + '№' + tag.id
    },
    goToDataTag(tag) {
      this.$router.push('/data/' + tag.id)
    },
    getTagsFromServer() {
      this.bigLoader = true
      this.getTags({project_id: this.currentProject.id}).then(result => {
        this.tags = result
        this.bigLoader = false
      })
    }
  },
  mounted() {
    this.getTagsFromServer()
  }
}
</script>

<style scoped>

</style>